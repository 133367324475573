define(['app', 'ePopup'], (app, ePopup) => {

  const fastTrackBadge = () => {
    const component = {};

    component.config = {
      selectors: {
        modalTrigger: '[data-js-element=modalTrigger]',
        modalContents: '[data-fastTrackModal-content]'
      },
      classes: {
        fastTrackModalPopup: 'fastTrackModal_popup'
      }
    };

    component.init = (element) => {
      component.element = element;

      component.modalTrigger = component.element.querySelector(component.config.selectors.modalTrigger);
      component.modalContents = component.element.querySelector(component.config.selectors.modalContents);

      component.bind();
      return component;
    }

    component.bind = () => {
      component.modalTrigger.addEventListener('click', component.fastTrackBadgeClicked)
    }

    component.fastTrackBadgeClicked = (e) => {
      if (e) {
        e.preventDefault();
      }

      component.epopup = new ePopup(
        component.modalContents,
        component.config.classes.fastTrackModalPopup,
        false,
        true,
        undefined,
        component.trackModalClose
      );
    }

    component.trackModalClose = () => {
      app.publish('columbo/track', 'fast_track_popup_close', 'click');
    }

    return component;
  }

  return fastTrackBadge;
});
